.Nav{
    background: #15171c;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.NavIcon{
    margin-left: 2rem;
    font-size: 2rem;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.SidebarNav{
    background: #15171c;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    transition: 350ms;
    z-index: 10;
}

.SidebarWrap{
    width: 100%;
    background: white;
}