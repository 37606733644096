.column {
    float: left;
    width: 33%;
    padding: 10px;
    text-align: left;
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }