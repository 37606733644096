

.Navbar {
  background-color: transparent;
  text-align: left;
  margin-bottom: 5vh;
  display: flex;
  align-items: center; 
  gap: 2vw;
  }

.Navbar h3{
  color: black;
  vertical-align: top;
  max-height: 14vh;
  margin: 0px;
  padding: 0px;
}

.NavbarLogo{
  vertical-align: middle;
  max-height: 5vh;
  }

.NavbarGoogleLogin{
  padding-right: 1vw;
}

