.toolbar {
  margin: auto;
  width: 80%;
}

.button {
    background-color: #6d44ff;
    border: none;
    color: white;
    padding: 15px 32px;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    float: left;
    width: 20%;
}

.clear { clear:both; }