body {font-family: Arial, Helvetica, sans-serif;}
* {box-sizing: border-box;}

/* Full-width input fields */
input[type=text]{
  width: 50%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
}




/* Set a style for all buttons */
button {
  background-color: #04AA6D;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}

button:hover {
  opacity:1;
}


/* Float cancel and signup buttons and add an equal width */
.cancelbtn, .signupbtn {
  float: center;
  width: 50%;
}

.display {
  width: 100%;
  margin: 0 auto;
  word-wrap: break-word
}

/* Add padding to container elements */
.container {
  width: 100%;
  padding: 16px;
}

/* The Modal (background) */
.modalGroup {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  width: 70%;
}

